@import "../../general/general.scss";

.register__container {
	@include flex($just: center, $align: center, $dir: column);
	// padding-bottom: 50px;

	@include media_3 {
		padding-inline: 30px;
		padding-bottom: 30px;
		align-items: flex-start;
	}

	.register__nav {
		width: 100%;
		max-width: $max_width;
		@include flex($just: flex-start, $gap: 2px);
		padding: 40px 0;

		@include media_1 {
			// padding-left: 30px;
			padding-top: 10px;
			padding-bottom: 20px;
		}

		@include media_4 {
			padding-bottom: 50px;
		}

		img {
			width: 50px;
			height: 33px;
			object-fit: contain;

			@include media_3 {
				width: 40px;
			}

			@include media_4 {
				width: 35px;
			}
		}

		p {
			@include font($size: 36px, $weight: bold);
			margin-bottom: 8px;

			@include media_3 {
				font-size: 30px;
			}

			@include media_4 {
				font-size: 26px;
			}
		}
	}

	.register_main {
		@include flex($just: center, $dir: column, $gap: 38px, $align: flex-start);

		.path {
			.path_cur {
				cursor: pointer;
				user-select: none;
				@include font($size: $font_size-7, $color: black);

				@include media_4 {
					font-size: 16px;
				}
			}

			@include flex($just: flex-start, $gap: 10px);

			img {
				user-select: none;
			}

			.path_next {
				user-select: none;
				cursor: pointer;
				@include font($size: $font_size-7, $color: #ccc8c8);

				@include media_4 {
					font-size: 16px;
				}
			}
		}

		.register_title {
			@include font($size: $font_size-3, $color: rgb(33, 33, 33), $weight: bold);

			@include media_3 {
				font-size: 30px;
			}

			@include media_register {
				margin-top: -20px;
				font-size: 24px;
			}

			@include media_4 {
				font-size: 19.5px;
				// min-width:
			}
		}

		.fields__sign {
			@include flex();
			width: 100%;

			@include media_register {
				flex-direction: column;
				align-items: flex-start;
				gap: 20px;
			}

			.fields {
				@include flex($dir: column, $just: center, $gap: 20px, $align: flex-start);

				@include media_register {
					gap: 20px;
				}

				.field {
					label {
						margin-bottom: 12px;
						@include font($size: $font_size-7);

						@include media_3 {
							font-size: 17px;
						}

						@include media_4 {
							font-size: 14px;
						}
					}

					input {
						position: relative;
						border-radius: 18px;
						border: solid 1px rgb(158, 194, 253);
						width: 342px;
						height: 56px;
						padding: 12px;
						@include font($size: $font_size-7);
						outline: none;
						box-shadow: 0px 0px 5px 0 rgba(158, 194, 253, 0.26);

						&::placeholder {
							@include font($size: $font_size-7, $color: #aaabbe);

							@include media_3 {
								font-size: 16px;
							}

							@include media_4 {
								font-size: 16px;
							}
						}

						@include media_3 {
							font-size: 16px;
						}

						@include media_4 {
							width: 320px;
							height: 53px;
						}
					}
				}

				.email__field {
					position: relative;

					.email__top {
						@include flex();

						width: 342px;

						@include media_4 {
							width: 320px;
						}

						.email_check {
							@include font($size: 14px, $color: #f3fafd);
							width: 78px;
							height: 30px;
							padding: 5px 8px;
							border-radius: 15px;
							background-color: #9ec2fd;
							border: none;
							margin-bottom: 5px;

						}

						.username_not_checked {
							cursor: pointer;
							background-color: #5695fb;

							&:hover {
								background-color: #478af5;
							}
						}
					}

					.icon_email {
						position: absolute;
						right: 5px;
						bottom: 18px;
						width: 18px;
					}

					.error_msg_top {
						position: absolute;
						top: -20px;
						@include font($size: 14px, $color: #ff7474);
					}

					.valid_msg {
						// position: absolute;
						@include font($size: 14px, $color: #3d94f9);
					}

					.error_msg {
						@include font($size: 14px, $color: #ff7474);
					}
				}

				.name__field {
					@include flex($dir: column, $align: flex-start);
				}

				.number__field {
					@include flex($dir: column, $align: flex-start);
				}

				.password__field {
					position: relative;
					@include flex($dir: column, $align: flex-start);

					div {
						position: relative;
					}

					input {
						margin-bottom: 10px;
					}

					.eye__icon {
						position: absolute;
						top: 26%;
						right: 10px;
					}

					.pass_match {
						@include font($size: 14px, $color: #3d94f9);
						margin-bottom: 10px
					}

					.pass_notmatch {
						@include font($size: 14px, $color: #ff7474);
						margin-bottom: 10px
					}

					.pass_notstrong {
						@include font($size: 14px, $color: #ff7474);
						margin-bottom: 10px
					}
				}
			}

			.sign {
				width: 100px;
				height: 100px;
				border-radius: 50%;
				@include flex($just: center);
				background-color: $primary_color;
				cursor: pointer;
				box-shadow: 0 3px 6px 0 lightblue;
				@include animation($time: 0.1s);

				&:hover {
					background-color: #7c9ef9;
				}

				img {
					width: 32px;
					height: 32px;
					object-fit: contain;
				}

				@include media_register {
					height: 60px;
					border-radius: 8px;
					width: 130px;
				}
			}
		}
	}
}