@import "../../../general/general.scss";

.navbar__container {
	width: 100%;
	// padding: 22px 0;
	position: relative;
	@include flex($just: center);
	position: absolute;
	height: 100px;
	top: 0;

	@include media_4 {
		align-items: flex-start;
	}

	@include media_1 {
		padding-inline: 30px;
		height: 80px;
	}

	@include media_3 {
		padding-inline: 0;
	}

	@include animation($time: 0.3s);
	background-color: #fff;
	z-index: 10;

	&.show_nav {
		position: fixed;
		background-color: #fff;
		height: 80px;
		border-bottom: 1px solid lightgray;
		// padding-top: px;
		// box-shadow: 3px 3px 0px hsl(0, 0%, 98%);

		@include media_1 {
			position: absolute;
			border-bottom: none;
		}

		@include media_3 {
			position: fixed;
			border-bottom: 1px solid lightgray;
		}
	}
	&.hidenav {
		border: none;
	}

	.mini__nav {
		position: absolute;
		width: 100%;
		visibility: hidden;
		@include flex($dir: column);
		@include animation();
		background-color: #fff;
		z-index: 9;
		padding: 30px 30px;
		left: 0;
		// visibility: hidden;
		// height: 0 !important;
		top: -50px;
		height: 120px;
		overflow: hidden;
		border-bottom: 1px solid lightgray;

		@include media_3 {
			visibility: visible;
		}

		a {
			text-decoration: none;
			@include font($size: $font_size-7, $color: rgb(45, 45, 45));

			@include media_4 {
				font-size: 16px;
			}
		}

		&.show {
			// height: 100px;
			transform: translateY(130px);
			visibility: visible;
			// transform: translateY(-40px);
		}
	}

	.nav__main {
		@include flex();
		max-width: $max_width;
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 10;
		background-color: #fff;

		@include media_1 {
			max-width: $max_width_2;
		}

		@include media_3 {
			width: 100%;
			max-width: 100%;
			padding-inline: 30px;
		}

		.nav_burger {
			display: none;

			@include media_3 {
				display: block;
				cursor: pointer;
			}
		}

		.nav_left {
			@include flex($just: flex-start, $gap: 42px);
			flex-basis: calc(100% - 200px);
			@include media_1 {
				gap: 40px;
			}
			@include media_3 {
				flex-basis: auto;
			}

			.nav_logo {
				@include flex($just: flex-start, $gap: 5px);
				cursor: pointer;
				@include media_3 {
					margin-left: 2rem;
					gap: 0;
				}

				img {
					object-fit: contain;
					width: 48px;
					height: 35px;
					margin: 0;
					-webkit-user-drag: none;

					@include media_3 {
						width: 5.5vw;
					}
				}

				.nav_logo_name {
					margin: 0;
					@include font($size: 30px, $weight: bold);
					margin-bottom: 6px;
					user-select: none;

					@include media_3 {
						font-size: 26px;
					}

					@include media_4 {
						font-size: 5vw;
					}
				}
			}

			.nav_links {
				@include media_3 {
					display: none;
				}
				margin-top: 5px;

				a {
					text-decoration: none;
					@include font($size: 18px, $color: rgb(86, 86, 86));
					width: 126px;
					margin-right: 25px;
					padding: 5px;
					// font-family: "NatoSansKR";

					@include media_1 {
						font-size: 18px;
					}
				}
			}
		}

		.nav_right {
			flex-basis: 300px;
			flex-shrink: 0;

			@include media_1 {
				flex-basis: 200px;
			}

			@include media_3 {
				flex-basis: auto;
			}

			.nav_login_btn {
				width: 100%;
				height: 56px;
				background-color: $primary_color;
				border: none;
				padding: 5px 15px;
				border-radius: 8px;
				box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
				cursor: pointer;
				@include animation($time: 0.1s);

				&:hover {
					background-color: #7c9ef9;
				}

				@include font($size: 28px, $color: #f3fafd);
				@include media_1 {
					font-size: 22px;
					height: 48px;
					padding: auto;
				}

				@include media_3 {
					font-size: 16px;
					width: auto;
					height: auto;
				}

				@include media_4 {
					font-size: 2vw;
					width: auto;
					height: auto;
				}
			}
		}
	}
}
