*,
body {
  margin: 0;
  box-sizing: border-box;
}

img {
  min-width: unset;
}

.login__container {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(45deg, #9ec2fd, #f3fafd);
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: auto;
}
@media screen and (max-width: 500px) {
  .login__container {
    justify-content: flex-start;
  }
}
.login__container .main__container {
  width: min(580px, 100vw);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  height: min(800px, 100vh);
  padding: 60px;
  border-radius: 18px;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d2d4fa;
  background-color: rgba(255, 255, 255, 0.98);
}
@media screen and (max-width: 600px) {
  .login__container .main__container {
    width: 100%;
    border-radius: 0;
  }
}
.login__container .main__container .logo__container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: auto;
}
.login__container .main__container .logo__container img {
  width: 50px;
  height: 33px;
  -o-object-fit: contain;
     object-fit: contain;
}
@media screen and (max-width: 500px) {
  .login__container .main__container .logo__container img {
    width: 40px;
    height: 28px;
  }
}
.login__container .main__container .logo__container p {
  font-family: "NatoSansKR";
  font-size: 36px;
  font-weight: bold;
  color: #30313c;
  margin-bottom: 5px;
}
@media screen and (max-width: 500px) {
  .login__container .main__container .logo__container p {
    font-size: 28px;
  }
}
.login__container .main__container .inputs {
  margin-top: 43px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
@media screen and (max-width: 500px) {
  .login__container .main__container .inputs {
    gap: 15px;
  }
}
.login__container .main__container .inputs .field {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.login__container .main__container .inputs .field label {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #30313c;
}
@media screen and (max-width: 500px) {
  .login__container .main__container .inputs .field label {
    font-size: 18px;
  }
}
.login__container .main__container .inputs .field input {
  position: relative;
  border-radius: 18px;
  border: solid 1px rgb(158, 194, 253);
  width: 342px;
  height: 56px;
  padding: 12px;
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #30313c;
  outline: none;
  box-shadow: 0px 0px 5px 0 rgba(158, 194, 253, 0.26);
}
.login__container .main__container .inputs .field input::-moz-placeholder {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #aaabbe;
}
.login__container .main__container .inputs .field input:-ms-input-placeholder {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #aaabbe;
}
.login__container .main__container .inputs .field input::placeholder {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #aaabbe;
}
@media screen and (max-width: 500px) {
  .login__container .main__container .inputs .field input::-moz-placeholder {
    font-size: 16px;
  }
  .login__container .main__container .inputs .field input:-ms-input-placeholder {
    font-size: 16px;
  }
  .login__container .main__container .inputs .field input::placeholder {
    font-size: 16px;
  }
}
@media screen and (max-width: 500px) {
  .login__container .main__container .inputs .field input {
    font-size: 16px;
  }
}
.login__container .main__container .inputs .field .eye__icon {
  position: absolute;
  top: 62%;
  right: 20px;
}
.login__container .main__container .inputs .find__data {
  text-decoration: none;
  width: 100%;
  text-align: end;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  gap: auto;
  margin-top: -10px;
  font-family: "NatoSansKR";
  font-size: 14px;
  font-weight: normal;
  color: #74a5f3;
}
.login__container .main__container .inputs .wrong__text {
  position: absolute;
  bottom: 0px;
  left: 0;
  font-family: "NatoSansKR";
  font-size: 12px;
  font-weight: normal;
  color: #ff7474;
}
.login__container .main__container .login__btn {
  width: 342px;
  height: 56px;
  padding: 11px 36px 10px;
  border-radius: 18px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #3d94f9;
  font-family: "NatoSansKR";
  font-size: 24px;
  font-weight: normal;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.login__container .main__container .login__btn:hover {
  background-color: #7c9ef9;
}
.login__container .main__container .login__footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  gap: auto;
}
@media screen and (max-width: 500px) {
  .login__container .main__container .login__footer {
    justify-content: space-between;
  }
}
.login__container .main__container .login__footer .left {
  cursor: pointer;
  font-family: "NatoSansKR";
  font-size: 18px;
  font-weight: normal;
  color: #aaabbe;
  padding-bottom: 5px;
}
@media screen and (max-width: 500px) {
  .login__container .main__container .login__footer .left {
    font-size: 14px;
  }
}
.login__container .main__container .login__footer .left:hover {
  text-decoration: underline;
}
.login__container .main__container .login__footer .right {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: auto;
  cursor: pointer;
  font-family: "NatoSansKR";
  font-size: 18px;
  font-weight: normal;
  color: #4a4a4b;
  padding-bottom: 5px;
}
.login__container .main__container .login__footer .right:hover {
  text-decoration: underline;
}
@media screen and (max-width: 500px) {
  .login__container .main__container .login__footer .right {
    font-size: 14px;
  }
  .login__container .main__container .login__footer .right img {
    width: 18px;
  }
}/*# sourceMappingURL=login.css.map */