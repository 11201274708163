@import "../../general/general.scss";

.select__container {
  @include flex($dir: column, $just: center);
  // height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: #e8f6fe;
  overflow: hidden;

  .select__box {
    min-width: 580px;
    margin-bottom: 100px;
    padding-top: 90px;
    @include flex($dir: column, $just: center, $align: center, $gap: 20px);

    .select__title {
      width: 100%;
      @include flex($dir: row, $just: space-between, $align: center);

      .select__title_main {
        @include font($size: 22px, $weight: bold, $color: #30313c);
      }

      .select__title_description {
        @include font($size: 14px, $weight: bold, $color: $primary_color);
      }
    }

    .select__shops {
      @include flex($dir: column, $gap: 10px);
      width: 100%;

      .select__shop {
        width: 100%;
        @include flex($dir: row, $align: center, $just: space-between);
        padding: 10px;

        .select__shop_header {
          @include flex($dir: row, $align: center, $gap: 10px);

          .select__shop_image {
            width: 44px;
            height: 44px;
            background-color: #ffffff;
            border-radius: 50%;
          }

          .select__shop_image_header {
            @include font($size: 18px, $weight: bold, $color: #30313c);
          }
        }

        .select__shop_select {
          width: 32px;
          height: 32px;
        }
      }

      .select__shop:hover {
        background-color: #ffffff;
        border-radius: 8px;
        cursor: pointer;
        // border: soli
      }
    }
  }
}