*,
body {
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

img {
  min-width: unset;
}

.terms__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 16px;
  margin-top: 100px;
  margin-bottom: 100px;
  padding-left: 25%;
  padding-right: 25%;
  overflow: hidden;
  white-space: pre-line;
}

.terms__container .terms__title {
  margin-top: 40px;
  font-family: "NatoSansKR";
  font-size: 36px;
  font-weight: bold;
  color: #30313c;
}

.terms__container .terms__subtitle {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: bold;
  color: #30313c;
}

.terms__container .terms__section {
  font-family: "NatoSansKR";
  font-size: 18px;
  font-weight: normal;
  color: #30313c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 16px;
}

.terms__container .terms__section .terms__subsection_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 12px;
  padding-left: 0px;
  margin-left: 28px;
}

.terms__container .terms__section .terms__subsection_row li {
  margin-left: 28px;
}

.terms__container .terms__section table,
.terms__container .terms__section th,
.terms__container .terms__section td {
  border: 1px solid black;
  border-collapse: collapse;
}

.terms__container .terms__section table {
  width: 100%;
  text-align: center;
}
