*,
body {
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

img {
  min-width: unset;
}

.navbar__container {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: auto;
  position: absolute;
  height: 100px;
  top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  z-index: 10;
}

@media screen and (max-width: 500px) {
  .navbar__container {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

@media screen and (max-width: 1350px) {
  .navbar__container {
    padding-inline: 30px;
    height: 80px;
  }
}

@media screen and (max-width: 768px) {
  .navbar__container {
    padding-inline: 0;
  }
}

.navbar__container.show_nav {
  position: fixed;
  background-color: #fff;
  height: 80px;
  border-bottom: 1px solid lightgray;
}

@media screen and (max-width: 1350px) {
  .navbar__container.show_nav {
    position: absolute;
    border-bottom: none;
  }
}

@media screen and (max-width: 768px) {
  .navbar__container.show_nav {
    position: fixed;
    border-bottom: 1px solid lightgray;
  }
}

.navbar__container.hidenav {
  border: none;
}

.navbar__container .mini__nav {
  position: absolute;
  width: 100%;
  visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: auto;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-color: #fff;
  z-index: 9;
  padding: 30px 30px;
  left: 0;
  top: -50px;
  height: 120px;
  overflow: hidden;
  border-bottom: 1px solid lightgray;
}

@media screen and (max-width: 768px) {
  .navbar__container .mini__nav {
    visibility: visible;
  }
}

.navbar__container .mini__nav a {
  text-decoration: none;
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #2d2d2d;
}

@media screen and (max-width: 500px) {
  .navbar__container .mini__nav a {
    font-size: 16px;
  }
}

.navbar__container .mini__nav.show {
  -webkit-transform: translateY(130px);
          transform: translateY(130px);
  visibility: visible;
}

.navbar__container .nav__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: auto;
  max-width: 1278px;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
  background-color: #fff;
}

@media screen and (max-width: 1350px) {
  .navbar__container .nav__main {
    max-width: 1100px;
  }
}

@media screen and (max-width: 768px) {
  .navbar__container .nav__main {
    width: 100%;
    max-width: 100%;
    padding-inline: 30px;
  }
}

.navbar__container .nav__main .nav_burger {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar__container .nav__main .nav_burger {
    display: block;
    cursor: pointer;
  }
}

.navbar__container .nav__main .nav_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 42px;
  -ms-flex-preferred-size: calc(100% - 200px);
      flex-basis: calc(100% - 200px);
}

@media screen and (max-width: 1350px) {
  .navbar__container .nav__main .nav_left {
    gap: 40px;
  }
}

@media screen and (max-width: 768px) {
  .navbar__container .nav__main .nav_left {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
}

.navbar__container .nav__main .nav_left .nav_logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .navbar__container .nav__main .nav_left .nav_logo {
    margin-left: 2rem;
    gap: 0;
  }
}

.navbar__container .nav__main .nav_left .nav_logo img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 48px;
  height: 35px;
  margin: 0;
  -webkit-user-drag: none;
}

@media screen and (max-width: 768px) {
  .navbar__container .nav__main .nav_left .nav_logo img {
    width: 5.5vw;
  }
}

.navbar__container .nav__main .nav_left .nav_logo .nav_logo_name {
  margin: 0;
  font-family: "NatoSansKR";
  font-size: 30px;
  font-weight: bold;
  color: #30313c;
  margin-bottom: 6px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media screen and (max-width: 768px) {
  .navbar__container .nav__main .nav_left .nav_logo .nav_logo_name {
    font-size: 26px;
  }
}

@media screen and (max-width: 500px) {
  .navbar__container .nav__main .nav_left .nav_logo .nav_logo_name {
    font-size: 5vw;
  }
}

.navbar__container .nav__main .nav_left .nav_links {
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .navbar__container .nav__main .nav_left .nav_links {
    display: none;
  }
}

.navbar__container .nav__main .nav_left .nav_links a {
  text-decoration: none;
  font-family: "NatoSansKR";
  font-size: 18px;
  font-weight: normal;
  color: #565656;
  width: 126px;
  margin-right: 25px;
  padding: 5px;
}

@media screen and (max-width: 1350px) {
  .navbar__container .nav__main .nav_left .nav_links a {
    font-size: 18px;
  }
}

.navbar__container .nav__main .nav_right {
  -ms-flex-preferred-size: 300px;
      flex-basis: 300px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

@media screen and (max-width: 1350px) {
  .navbar__container .nav__main .nav_right {
    -ms-flex-preferred-size: 200px;
        flex-basis: 200px;
  }
}

@media screen and (max-width: 768px) {
  .navbar__container .nav__main .nav_right {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
}

.navbar__container .nav__main .nav_right .nav_login_btn {
  width: 100%;
  height: 56px;
  background-color: #3d94f9;
  border: none;
  padding: 5px 15px;
  border-radius: 8px;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  font-family: "NatoSansKR";
  font-size: 28px;
  font-weight: normal;
  color: #f3fafd;
}

.navbar__container .nav__main .nav_right .nav_login_btn:hover {
  background-color: #7c9ef9;
}

@media screen and (max-width: 1350px) {
  .navbar__container .nav__main .nav_right .nav_login_btn {
    font-size: 22px;
    height: 48px;
    padding: auto;
  }
}

@media screen and (max-width: 768px) {
  .navbar__container .nav__main .nav_right .nav_login_btn {
    font-size: 16px;
    width: auto;
    height: auto;
  }
}

@media screen and (max-width: 500px) {
  .navbar__container .nav__main .nav_right .nav_login_btn {
    font-size: 2vw;
    width: auto;
    height: auto;
  }
}
