@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
} */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* text-align: center; */
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */

  /* padding: 20px; */
  /* border: 1px solid #eee; */
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); subtle shadow for depth */
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.mySwiper {
  width: 100%; /* Set Swiper's width to 50% of its container */
  /* height: 600px; */
  margin: auto;
}
.swiper-button-prev,
.swiper-button-next {
  color: #000;
  /* background-color: #fff; */
  border-radius: 50%;
  padding: 10px;
}

/* Adjust the position of navigation buttons if needed */
.swiper-button-prev {
  left: 10px;
}

.swiper-button-next {
  right: 10px;
}

/* Style for the pagination (if you decide to use it) */
.swiper-pagination-bullet {
  background: #000;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #ffffff;
}
