*,
body {
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

img {
  min-width: unset;
}

.forgot__container {
  height: 100vh;
  width: 100vw;
  background: -webkit-gradient(linear, left top, left bottom, from(#3d94f9), to(#f3fafd));
  background: linear-gradient(180deg, #3d94f9, #f3fafd);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 58px;
}

@media screen and (max-width: 500px) {
  .forgot__container {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}

.forgot__container .inputs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 24px;
}

@media screen and (max-width: 500px) {
  .forgot__container .inputs {
    gap: 15px;
  }
}

.forgot__container .inputs label {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}

@media screen and (max-width: 500px) {
  .forgot__container .inputs label {
    font-size: 18px;
  }
}

.forgot__container .inputs .field {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: px;
}

.forgot__container .inputs .field label {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #30313c;
}

@media screen and (max-width: 500px) {
  .forgot__container .inputs .field label {
    font-size: 18px;
  }
}

.forgot__container .inputs .field input {
  position: relative;
  border-radius: 18px;
  border: solid 1px #9ec2fd;
  width: 342px;
  height: 56px;
  padding: 12px;
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #30313c;
  outline: none;
  -webkit-box-shadow: 0px 0px 5px 0 rgba(158, 194, 253, 0.26);
          box-shadow: 0px 0px 5px 0 rgba(158, 194, 253, 0.26);
}

.forgot__container .inputs .field input::-webkit-input-placeholder {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #aaabbe;
}

.forgot__container .inputs .field input:-ms-input-placeholder {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #aaabbe;
}

.forgot__container .inputs .field input::-ms-input-placeholder {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #aaabbe;
}

.forgot__container .inputs .field input::placeholder {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #aaabbe;
}

@media screen and (max-width: 500px) {
  .forgot__container .inputs .field input::-webkit-input-placeholder {
    font-size: 16px;
  }
  .forgot__container .inputs .field input:-ms-input-placeholder {
    font-size: 16px;
  }
  .forgot__container .inputs .field input::-ms-input-placeholder {
    font-size: 16px;
  }
  .forgot__container .inputs .field input::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .forgot__container .inputs .field input {
    font-size: 16px;
  }
}

.forgot__container .inputs .field .eye__icon {
  position: absolute;
  top: 35%;
  right: 20px;
}

.forgot__container .inputs .login__btn {
  width: 342px;
  height: 56px;
  padding: 11px 36px 10px;
  border-radius: 18px;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #3d94f9;
  font-family: "NatoSansKR";
  font-size: 24px;
  font-weight: normal;
  color: #fff;
  border: none;
  cursor: pointer;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.forgot__container .inputs .login__btn:hover {
  background-color: #7c9ef9;
}
