$primary_color: #3d94f9;

$font_color: #30313c;
$font_family: "NatoSansKR";
$font_size-1: 64px;
$font_size-2: 46px;
$font_size-3: 36px;
$font_size-4: 30px;
$font_size-5: 28px;
$font_size-6: 24px;
$font_size-7: 20px;
$font_size_8: 32px;

$max_width: 1278px;
$max_width_2: 1100px;
$min_width: 375px;

$breakpoint_1: 1350px;
$breakpoint_2: 1100px;
$breakpoint_3: 768px;
$breakpoint_4: 500px;
$breakpoint_login: 600px;
$breakpoint_register: 612px;
$breakpoint_signup: 400px;

*,
body {
  margin: 0;
  box-sizing: border-box;
}

img {
  min-width: unset;
}

@mixin flex($dir: row, $just: space-between, $align: center, $gap: auto) {
  display: flex;
  justify-content: $just;
  flex-direction: $dir;
  align-items: $align;
  gap: $gap;
}

@mixin font(
  $family: $font_family,
  $size: $font_size-3,
  $weight: normal,
  $color: $font_color
) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  color: $color;
}

@mixin animation($type: ease-in-out, $time: 0.5s) {
  transition: all $time $type;
}

@mixin media_1 {
  @media screen and (max-width: $breakpoint_1) {
    @content;
  }
}

@mixin media_2 {
  @media screen and (max-width: $breakpoint_2) {
    @content;
  }
}

@mixin media_3 {
  @media screen and (max-width: $breakpoint_3) {
    @content;
  }
}

@mixin media_4 {
  @media screen and (max-width: $breakpoint_4) {
    @content;
  }
}

@mixin media_login {
  @media screen and (max-width: $breakpoint_login) {
    @content;
  }
}

@mixin media_register {
  @media screen and (max-width: $breakpoint_register) {
    @content;
  }
}

@mixin media_signup {
  @media screen and (max-width: $breakpoint_signup) {
    @content;
  }
}
