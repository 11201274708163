@import "../../general//general.scss";

.login__container {
	height: 100vh;
	width: 100vw;
	background: linear-gradient(45deg, #9ec2fd, #f3fafd);
	@include flex($just: center);

	@include media_4 {
		justify-content: flex-start;
	}

	.main__container {
		width: min(580px, 100vw);
		@include flex($dir: column, $just: flex-start, $gap: 30px);
		height: min(800px, 100vh);
		padding: 60px;
		border-radius: 18px;
		box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
		border: solid 1px #d2d4fa;
		background-color: rgba(255, 255, 255, 0.98);

		@include media_login {
			width: 100%;
			border-radius: 0;
		}

		.logo__container {
			@include flex($just: center);

			img {
				width: 50px;
				height: 33px;
				object-fit: contain;

				@include media_4 {
					width: 40px;
					height: 28px;
				}
			}

			p {
				@include font($size: 36px, $weight: bold);
				margin-bottom: 5px;

				@include media_4 {
					font-size: 28px;
				}
			}
		}

		.inputs {
			margin-top: 43px;
			position: relative;
			@include flex($dir: column, $gap: 30px);

			@include media_4 {
				gap: 15px;
			}

			.field {
				position: relative;
				label {
					@include font($size: $font_size-7);
					@include media_4 {
						font-size: 18px;
					}
				}
				input {
					position: relative;
					border-radius: 18px;
					border: solid 1px rgb(158, 194, 253);
					width: 342px;
					height: 56px;
					padding: 12px;
					@include font($size: $font_size-7);
					outline: none;
					box-shadow: 0px 0px 5px 0 rgba(158, 194, 253, 0.26);

					&::placeholder {
						@include font($size: $font_size-7, $color: #aaabbe);
						@include media_4 {
							font-size: 16px;
						}
					}
					@include media_4 {
						font-size: 16px;
					}
				}

				.eye__icon {
					position: absolute;
					top: 62%;
					right: 20px;
				}
				@include flex($dir: column, $align: flex-start, $gap: 10px);
			}

			.find__data {
				text-decoration: none;
				width: 100%;
				text-align: end;
				@include flex($just: flex-end);
				margin-top: -10px;
				@include font($size: 14px, $color: #74a5f3);
			}

			.wrong__text {
				position: absolute;
				bottom: 0px;
				left: 0;
				@include font($size: 12px, $color: #ff7474);
			}
		}

		.login__btn {
			width: 342px;
			height: 56px;
			padding: 11px 36px 10px;
			border-radius: 18px;
			box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
			background-color: #3d94f9;
			@include font($color: #fff, $size: $font_size-6);
			border: none;
			cursor: pointer;
			@include animation($time: 0.1s);

			&:hover {
				background-color: #7c9ef9;
			}
		}

		.login__footer {
			width: 100%;
			@include flex($just: space-around);
			@include media_4 {
				justify-content: space-between;
			}

			.left {
				cursor: pointer;
				@include font($size: 18px, $color: #aaabbe);
				padding-bottom: 5px;

				@include media_4 {
					font-size: 14px;
				}

				&:hover {
					text-decoration: underline;
				}
			}
			.right {
				@include flex;
				cursor: pointer;
				@include font($size: 18px, $color: #4a4a4b);
				padding-bottom: 5px;

				&:hover {
					text-decoration: underline;
				}
				@include media_4 {
					font-size: 14px;

					img {
						width: 18px;
					}
				}
			}
		}
	}
}
