*,
body {
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

img {
  min-width: unset;
}

.select__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: auto;
  min-height: 100vh;
  width: 100%;
  background-color: #e8f6fe;
  overflow: hidden;
}

.select__container .select__box {
  min-width: 580px;
  margin-bottom: 100px;
  padding-top: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.select__container .select__box .select__title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: auto;
}

.select__container .select__box .select__title .select__title_main {
  font-family: "NatoSansKR";
  font-size: 22px;
  font-weight: bold;
  color: #30313c;
}

.select__container .select__box .select__title .select__title_description {
  font-family: "NatoSansKR";
  font-size: 14px;
  font-weight: bold;
  color: #3d94f9;
}

.select__container .select__box .select__shops {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  width: 100%;
}

.select__container .select__box .select__shops .select__shop {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: auto;
  padding: 10px;
}

.select__container .select__box .select__shops .select__shop .select__shop_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.select__container .select__box .select__shops .select__shop .select__shop_header .select__shop_image {
  width: 44px;
  height: 44px;
  background-color: #ffffff;
  border-radius: 50%;
}

.select__container .select__box .select__shops .select__shop .select__shop_header .select__shop_image_header {
  font-family: "NatoSansKR";
  font-size: 18px;
  font-weight: bold;
  color: #30313c;
}

.select__container .select__box .select__shops .select__shop .select__shop_select {
  width: 32px;
  height: 32px;
}

.select__container .select__box .select__shops .select__shop:hover {
  background-color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
}
