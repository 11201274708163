@import "../../general//general.scss";

.forgot__container {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(180deg, #3d94f9, #f3fafd);
    @include flex($just: center, $gap: 58px, $dir: column);

    @include media_4 {
        justify-content: flex-start;
    }

    .inputs {
        @include flex($dir: column, $gap: 24px, $align: center);

        @include media_4 {
            gap: 15px;
        }

        label {
            @include font($size: $font_size-7, $color: #ffffff, $weight: bold);

            @include media_4 {
                font-size: 18px;
            }
        }


        .field {
            position: relative;

            label {
                @include font($size: $font_size-7);

                @include media_4 {
                    font-size: 18px;
                }
            }

            input {
                position: relative;
                border-radius: 18px;
                border: solid 1px rgb(158, 194, 253);
                width: 342px;
                height: 56px;
                padding: 12px;
                @include font($size: $font_size-7);
                outline: none;
                box-shadow: 0px 0px 5px 0 rgba(158, 194, 253, 0.26);

                &::placeholder {
                    @include font($size: $font_size-7, $color: #aaabbe);

                    @include media_4 {
                        font-size: 16px;
                    }
                }

                @include media_4 {
                    font-size: 16px;
                }
            }

            .eye__icon {
                position: absolute;
                top: 35%;
                right: 20px;
            }

            @include flex($dir: column, $align: flex-start, $gap: px);
        }

        .login__btn {
            width: 342px;
            height: 56px;
            padding: 11px 36px 10px;
            border-radius: 18px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            background-color: #3d94f9;
            @include font($color: #fff, $size: $font_size-6);
            border: none;
            cursor: pointer;
            @include animation($time: 0.1s);

            &:hover {
                background-color: #7c9ef9;
            }
        }
    }


}