*,
body {
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

img {
  min-width: unset;
}

.register__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: auto;
}

@media screen and (max-width: 768px) {
  .register__container {
    padding-inline: 30px;
    padding-bottom: 30px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.register__container .register__nav {
  width: 100%;
  max-width: 1278px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2px;
  padding: 40px 0;
}

@media screen and (max-width: 1350px) {
  .register__container .register__nav {
    padding-top: 10px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 500px) {
  .register__container .register__nav {
    padding-bottom: 50px;
  }
}

.register__container .register__nav img {
  width: 50px;
  height: 33px;
  -o-object-fit: contain;
     object-fit: contain;
}

@media screen and (max-width: 768px) {
  .register__container .register__nav img {
    width: 40px;
  }
}

@media screen and (max-width: 500px) {
  .register__container .register__nav img {
    width: 35px;
  }
}

.register__container .register__nav p {
  font-family: "NatoSansKR";
  font-size: 36px;
  font-weight: bold;
  color: #30313c;
  margin-bottom: 8px;
}

@media screen and (max-width: 768px) {
  .register__container .register__nav p {
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .register__container .register__nav p {
    font-size: 26px;
  }
}

.register__container .register_main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 38px;
}

.register__container .register_main .path {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.register__container .register_main .path .path_cur {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: black;
}

@media screen and (max-width: 500px) {
  .register__container .register_main .path .path_cur {
    font-size: 16px;
  }
}

.register__container .register_main .path img {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.register__container .register_main .path .path_next {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #ccc8c8;
}

@media screen and (max-width: 500px) {
  .register__container .register_main .path .path_next {
    font-size: 16px;
  }
}

.register__container .register_main .register_title {
  font-family: "NatoSansKR";
  font-size: 36px;
  font-weight: bold;
  color: #212121;
}

@media screen and (max-width: 768px) {
  .register__container .register_main .register_title {
    font-size: 30px;
  }
}

@media screen and (max-width: 612px) {
  .register__container .register_main .register_title {
    margin-top: -20px;
    font-size: 24px;
  }
}

@media screen and (max-width: 500px) {
  .register__container .register_main .register_title {
    font-size: 19.5px;
  }
}

.register__container .register_main .fields__sign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: auto;
  width: 100%;
}

@media screen and (max-width: 612px) {
  .register__container .register_main .fields__sign {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 20px;
  }
}

.register__container .register_main .fields__sign .fields {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 20px;
}

@media screen and (max-width: 612px) {
  .register__container .register_main .fields__sign .fields {
    gap: 20px;
  }
}

.register__container .register_main .fields__sign .fields .field label {
  margin-bottom: 12px;
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #30313c;
}

@media screen and (max-width: 768px) {
  .register__container .register_main .fields__sign .fields .field label {
    font-size: 17px;
  }
}

@media screen and (max-width: 500px) {
  .register__container .register_main .fields__sign .fields .field label {
    font-size: 14px;
  }
}

.register__container .register_main .fields__sign .fields .field input {
  position: relative;
  border-radius: 18px;
  border: solid 1px #9ec2fd;
  width: 342px;
  height: 56px;
  padding: 12px;
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #30313c;
  outline: none;
  -webkit-box-shadow: 0px 0px 5px 0 rgba(158, 194, 253, 0.26);
          box-shadow: 0px 0px 5px 0 rgba(158, 194, 253, 0.26);
}

.register__container .register_main .fields__sign .fields .field input::-webkit-input-placeholder {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #aaabbe;
}

.register__container .register_main .fields__sign .fields .field input:-ms-input-placeholder {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #aaabbe;
}

.register__container .register_main .fields__sign .fields .field input::-ms-input-placeholder {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #aaabbe;
}

.register__container .register_main .fields__sign .fields .field input::placeholder {
  font-family: "NatoSansKR";
  font-size: 20px;
  font-weight: normal;
  color: #aaabbe;
}

@media screen and (max-width: 768px) {
  .register__container .register_main .fields__sign .fields .field input::-webkit-input-placeholder {
    font-size: 16px;
  }
  .register__container .register_main .fields__sign .fields .field input:-ms-input-placeholder {
    font-size: 16px;
  }
  .register__container .register_main .fields__sign .fields .field input::-ms-input-placeholder {
    font-size: 16px;
  }
  .register__container .register_main .fields__sign .fields .field input::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .register__container .register_main .fields__sign .fields .field input::-webkit-input-placeholder {
    font-size: 16px;
  }
  .register__container .register_main .fields__sign .fields .field input:-ms-input-placeholder {
    font-size: 16px;
  }
  .register__container .register_main .fields__sign .fields .field input::-ms-input-placeholder {
    font-size: 16px;
  }
  .register__container .register_main .fields__sign .fields .field input::placeholder {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .register__container .register_main .fields__sign .fields .field input {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .register__container .register_main .fields__sign .fields .field input {
    width: 320px;
    height: 53px;
  }
}

.register__container .register_main .fields__sign .fields .email__field {
  position: relative;
}

.register__container .register_main .fields__sign .fields .email__field .email__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: auto;
  width: 342px;
}

@media screen and (max-width: 500px) {
  .register__container .register_main .fields__sign .fields .email__field .email__top {
    width: 320px;
  }
}

.register__container .register_main .fields__sign .fields .email__field .email__top .email_check {
  font-family: "NatoSansKR";
  font-size: 14px;
  font-weight: normal;
  color: #f3fafd;
  width: 78px;
  height: 30px;
  padding: 5px 8px;
  border-radius: 15px;
  background-color: #9ec2fd;
  border: none;
  margin-bottom: 5px;
}

.register__container .register_main .fields__sign .fields .email__field .email__top .username_not_checked {
  cursor: pointer;
  background-color: #5695fb;
}

.register__container .register_main .fields__sign .fields .email__field .email__top .username_not_checked:hover {
  background-color: #478af5;
}

.register__container .register_main .fields__sign .fields .email__field .icon_email {
  position: absolute;
  right: 5px;
  bottom: 18px;
  width: 18px;
}

.register__container .register_main .fields__sign .fields .email__field .error_msg_top {
  position: absolute;
  top: -20px;
  font-family: "NatoSansKR";
  font-size: 14px;
  font-weight: normal;
  color: #ff7474;
}

.register__container .register_main .fields__sign .fields .email__field .valid_msg {
  font-family: "NatoSansKR";
  font-size: 14px;
  font-weight: normal;
  color: #3d94f9;
}

.register__container .register_main .fields__sign .fields .email__field .error_msg {
  font-family: "NatoSansKR";
  font-size: 14px;
  font-weight: normal;
  color: #ff7474;
}

.register__container .register_main .fields__sign .fields .name__field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: auto;
}

.register__container .register_main .fields__sign .fields .number__field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: auto;
}

.register__container .register_main .fields__sign .fields .password__field {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: auto;
}

.register__container .register_main .fields__sign .fields .password__field div {
  position: relative;
}

.register__container .register_main .fields__sign .fields .password__field input {
  margin-bottom: 10px;
}

.register__container .register_main .fields__sign .fields .password__field .eye__icon {
  position: absolute;
  top: 26%;
  right: 10px;
}

.register__container .register_main .fields__sign .fields .password__field .pass_match {
  font-family: "NatoSansKR";
  font-size: 14px;
  font-weight: normal;
  color: #3d94f9;
  margin-bottom: 10px;
}

.register__container .register_main .fields__sign .fields .password__field .pass_notmatch {
  font-family: "NatoSansKR";
  font-size: 14px;
  font-weight: normal;
  color: #ff7474;
  margin-bottom: 10px;
}

.register__container .register_main .fields__sign .fields .password__field .pass_notstrong {
  font-family: "NatoSansKR";
  font-size: 14px;
  font-weight: normal;
  color: #ff7474;
  margin-bottom: 10px;
}

.register__container .register_main .fields__sign .sign {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: auto;
  background-color: #3d94f9;
  cursor: pointer;
  -webkit-box-shadow: 0 3px 6px 0 lightblue;
          box-shadow: 0 3px 6px 0 lightblue;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.register__container .register_main .fields__sign .sign:hover {
  background-color: #7c9ef9;
}

.register__container .register_main .fields__sign .sign img {
  width: 32px;
  height: 32px;
  -o-object-fit: contain;
     object-fit: contain;
}

@media screen and (max-width: 612px) {
  .register__container .register_main .fields__sign .sign {
    height: 60px;
    border-radius: 8px;
    width: 130px;
  }
}
