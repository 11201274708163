@import "../../general/general.scss";

.terms__container {
    @include flex($dir: column, $just: flex-start, $align: flex-start, $gap: 16px);
    margin-top: 100px;
    margin-bottom: 100px;
    padding-left: 25%;
    padding-right: 25%;
    overflow: hidden;
    white-space: pre-line;

    .terms__title {
        margin-top: 40px;
        @include font($size: 36px, $weight: bold);
    }

    .terms__subtitle {
        @include font($size: 20px, $weight: bold);
    }

    .terms__section {
        @include font($size: 18px);
        @include flex($dir: column, $just: flex-start, $align: flex-start, $gap: 16px);

        .terms__subsection_row {

            @include flex($dir: row, $just: flex-start, $align: flex-start, $gap: 12px);
            padding-left: 0px;
            margin-left: 28px;

            li {
                margin-left: 28px;
            }
        }

        table,
        th,
        td {
            border: 1px solid black;
            border-collapse: collapse;
        }

        table {
            width: 100%;
            text-align: center;
        }
    }
}